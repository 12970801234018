@use "../utils" as *;

/*----------------------------------------*/
/* 19. Extra
/*----------------------------------------*/

.team-one .swiper .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  border-radius: 2px;
  background: #fff;
  opacity: 1;
  display: inline-block;
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
  &.swiper-pagination-bullet-active {
    border-radius: 2px;
    width: 16px;
    background: var(--theme-1);
  }
}

.scroll-to-top {
  background-color: #13c4a1 !important;
  z-index: 999 !important;
  color: #fff !important;
}
