@use "../utils" as *;

/*----------------------------------------*/
/* 07. Section-title
/*----------------------------------------*/

.section-title-shape-one {
  h3 {
    font-size: 14px;
    line-height: 1.2;
    color: var(--theme-1);
    text-transform: uppercase;
    font-family: var(--ff-body);
    &.white {
      color: var(--common-white);
    }
  }
  h2 {
    margin-top: 15px;
    font-size: 26px;
    line-height: 1.2;
    text-transform: capitalize;
    @media #{$xs, $xss} {
      font-size: 22px;
    }
  }
  p {
    margin-top: 15px;
    margin-bottom: 0;
  }

  &.middle {
    text-align: center;
    padding: 0 150px;
    h3 {
      color: var(--common-white);
    }
    @media #{$md, $sm, $xs, $xss} {
      padding: 0 75px;
    }
    @media #{$sm, $xs, $xss} {
      padding: 0;
      text-align: start;
    }
  }
  &.left {
    h3 {
      color: var(--common-white);
    }
  }
  &.black {
    h3 {
      color: var(--text-p-1);
    }
    h2 {
      color: var(--text-h);
    }
    p {
      color: var(--text-p-1);
    }
  }
}
