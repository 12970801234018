@use "../utils" as *;

/*----------------------------------------*/
/* 22. Breadcrumb
/*----------------------------------------*/

.breadcrumb {
  padding: 120px 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 9;
  margin-bottom: 0;
  overflow: hidden;
  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #0006;
    z-index: 0;
  }
  .breadcrumb-inner {
    position: relative;
    z-index: 9;
    h2 {
      font-size: 36px;
      font-weight: var(--fw-700);
      margin: 0;
    }
    .link-shape {
      margin-top: 5px;
      a {
        font-size: 16px;
        font-weight: 600;
        &:hover {
          color: var(--theme-1);
        }
      }
      span {
        font-size: 16px;
        font-weight: 600;
        color: var(--theme-1);
      }
    }
  }
  .shape-img {
    .shape-1 {
      right: 115px;
      bottom: 22%;
      z-index: 2;
      animation: trigger 6s linear infinite;
      width: 140px;
      @media #{$sm, $xs, $xss} {
        right: 26px;
      }
    }
    .shape-2 {
      right: 35%;
      bottom: 67%;
      width: 20px;
      z-index: 1;
      animation: animationFramesOne 35s linear infinite;
      -webkit-animation: animationFramesOne 35s linear infinite;
    }
    .shape-3 {
      right: 45%;
      bottom: 57%;
      width: 26px;
      z-index: 1;
      animation: animationFramesOne 55s linear infinite reverse;
      -webkit-animation: animationFramesOne 55s linear infinite reverse;
    }
    .shape-5 {
      right: 56%;
      bottom: 62%;
      width: 15px;
      z-index: 1;
      animation: animationFramesOne 60s linear infinite;
      -webkit-animation: animationFramesOne 60s linear infinite;
    }
    .shape-4 {
      right: 26%;
      bottom: 8%;
      width: 35px;
      z-index: 1;
      animation: rotate-in-center 10s linear 0s infinite;
      -webkit-animation: rotate-in-center 10s linear 0s infinite;
    }
  }
}
